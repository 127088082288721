
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import * as rules from '../../../rules/rules'
import { InstructionDsp } from '../../../../types/instruction_type'
import { $GOOGLEADS, $META } from '../../../../config/dspConfig'

@Component({
  components: {}
})
export default class IOIdentityForm extends Vue {
  @Prop({ required: true }) dsp: string

  identity = {
    insertionOrderId: '',
    advertiserExternalId: '',
    memberExternalId: ''
  }

  errorIoAlreadyExists = false

  rules = rules

  formState = true

  close () {
    this.$emit('close')
  }

  next () {
    this.$emit('next', this.identity)
  }

  get labelIO () {
    return this.$dspConfig[this.dsp as InstructionDsp].ioFieldLabel
  }

  get labelAdvertiser () {
    if (this.dsp === $META) {
      return 'Advertiser id'
    }
    return this.$dspConfig[this.dsp as InstructionDsp].adLabel
  }

  get labelMember () {
    if (this.dsp === $GOOGLEADS) {
      return 'Manager Id'
    }
    return 'Business manager id'
  }

  @Watch('identity', { deep: true })
  async onChangeIdentity () {
    if (!this.identity.insertionOrderId) {
      return
    }
    const data = {
      campaign_id: String(this.identity.insertionOrderId),
      dsp: this.dsp
    }
    const response = await this.$apiCaller.getInstructions(data)

    if (this.$apiCaller.isResponseError(response)) {
      this.$store.commit('setErrorMessageWithResponse', response)
    } else {
      this.errorIoAlreadyExists = response.data && response.data.length > 0
    }
  }
}
